import React from 'react'

import LiveLogin from '../../components/forms/live/LiveLogin'
import { H1, QueryParamMessage } from '../../components/shared'
import Layout from '../../templates/LiveLayout'
import * as S from '../../styles/login-live.styles'
import { PageRendererProps } from 'gatsby'
import * as Colors from '../../constants/colors'
import { withErrorHandler } from '../../components/errorHandler'

const LoginPage = (props: PageRendererProps): React.ReactElement => (
  <Layout title="" hideSideMenu={true} location={props.location}>
    <S.Container>
      <S.Wrap>
        <H1 color={Colors.themeColors.textColor}>Login</H1>
        <QueryParamMessage />
        <LiveLogin context="live" />
      </S.Wrap>
    </S.Container>
  </Layout>
)

export default withErrorHandler(LoginPage)
