import React from 'react'
import { Link } from 'gatsby'
import { IInputTypeProps } from '../util/Input'
import { Text, ErrorMessage, UnusuallyLongRequestMessage } from '../../shared'
import {
  AUTH_FORGOT_PASSWORD_ROUTE,
  LIVE_SCHEDULE_ROUTE,
  TRAINER_SCHEDULE,
} from '../../../constants/routes'
import {
  isDisabledGenerator,
  defaultErrorMsg,
  handleChangeGenerator,
} from '../util/helpers'
import { IRequestState } from '../util/types'
import * as session from '../../../util/session'
import { fetchLogin } from '../../../util/fetch'
import { themeColors } from '../../../constants/colors'
import { navigate } from 'gatsby'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

interface ILoginBody {
  email: string
  password: string
}

type ILoginState = ILoginBody & IRequestState

interface IProps {
  context?: 'live'
  isTrainer?: boolean
}

class LiveLogin extends React.Component<IProps, ILoginState> {
  private static inputTypes: { [s in keyof ILoginBody]: IInputTypeProps } = {
    email: {
      autoFocus: true,
      label: 'Email',
      type: 'email',
      required: true,
      autoComplete: 'email',
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
      autoComplete: 'current-password',
    },
  }

  private static isDisabled = isDisabledGenerator(LiveLogin.inputTypes)

  public constructor(props: {}) {
    super(props)
    this.state = { pending: false, email: '', password: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  private handleChange = handleChangeGenerator(this)

  private handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (LiveLogin.isDisabled(this.state)) return
    this.setState({ pending: true, error: undefined })
    const { email, password } = this.state

    fetchLogin({ email, password })
      .then(res => {
        const { access_token: accessToken, refresh_token: refreshToken } = res
        try {
          session.login(accessToken, refreshToken, true)
          if (this.props.context === 'live') {
            if (this.props.isTrainer) {
              navigate(TRAINER_SCHEDULE)
            } else {
              navigate(LIVE_SCHEDULE_ROUTE)
            }
          }
        } catch (err) {
          this.setState({
            pending: false,
            error: (err && err.message) || 'There was an issue logging you in.',
          })
        }
      })
      .catch(err => {
        this.setState({
          pending: false,
          error: (err && err.message) || defaultErrorMsg,
        })
      })
  }

  handleChangeInput = (e: any, name: 'email' | 'password') => {
    // @ts-ignore
    this.setState({ [name]: e.target.value })
  }

  public render(): React.ReactElement {
    const { context } = this.props
    const isLoggedIn = session.isLoggedIn()
    if (isLoggedIn) {
      navigate(LIVE_SCHEDULE_ROUTE)
    }
    const { pending, error } = this.state
    const isDisabled = LiveLogin.isDisabled(this.state)

    return (
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
          this.handleSubmit(e)
        }
      >
        <ErrorMessage message={error} />
        <UnusuallyLongRequestMessage pending={pending} />
        <TextField
          value={this.state.email}
          label="Email"
          onChange={value => this.handleChangeInput(value, 'email')}
          variant="outlined"
          type="email"
          name="email"
          style={{ width: '100%', marginBottom: 20 }}
        />

        <TextField
          value={this.state.password}
          label="Password"
          type="password"
          onChange={value => this.handleChangeInput(value, 'password')}
          variant="outlined"
          style={{ width: '100%', marginBottom: 20 }}
        />

        <Button
          variant={'contained'}
          disabled={isDisabled || pending}
          type="submit"
          color="primary"
          style={{
            textTransform: 'capitalize',
            marginTop: 10,
            marginBottom: 20,
          }}
          fullWidth
        >
          {pending ? 'Logging in...' : 'Login'}
        </Button>
        {/* <Text sm mb1 color={themeColors.textColor}>
          Need an athlete account ?{' '}
          <Link
            style={{ color: themeColors.primary }}
            to={context === 'live' ? LIVE_REGISTER : REGISTER_ROUTE}
          >
            Register here.
          </Link>
        </Text> */}
        {context !== 'live' && (
          <Text sm>
            <Link
              to={AUTH_FORGOT_PASSWORD_ROUTE}
              style={{ color: themeColors.primary }}
            >
              Forgot your password?
            </Link>
          </Text>
        )}
      </form>
    )
  }
}

export default LiveLogin
